import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  Hidden
} from '@material-ui/core';

export const DialogConvocatoria = ({ open, handleClose }) => {
  const url = 'https://fcpcutn.com.ec/files';
  const nameFile = 'convocatoria-asamblea-ordinaria.pdf';

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Hidden only={['xs']}>
              <iframe title="pdf" src={`${url}/${nameFile}`} width="100%" height="500px"></iframe>
            </Hidden>
            <Hidden only={['md', 'xl', 'lg', 'sm']}>
              <div>
                <p>
                  Abrir PDF &nbsp;
                  <a rel="noreferrer" href={`${url}/${nameFile}`} target="_blank">
                    aquí
                  </a>
                  .
                </p>
              </div>
            </Hidden>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
